.navbar {
  padding-top: 24px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.navbar .navbar-nav a {
  font-size: 24px;
  font-weight: 300;
  margin-right: 60px;
  padding-bottom: 0;
}

.Header-light {
  background-color: #f2f2f2;
}

.Header-dark {
  background-color: #2c2c2c;
}

.Header-light.navbar-light .navbar-nav .nav-link {
  color: #2c2c2c;
}

.Header-dark.navbar-light .navbar-nav .nav-link {
  color: #f2f2f2;
}
