.Home h1 {
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
}

.Home-container {
  background: url(./Stars.svg) no-repeat center,
    linear-gradient(#E6CFBD 0%, #D9B9BE 51.04%, #ACD2E6 100%);
  background-size: cover;
  margin-bottom: -2px; /* hack to solve a ghost line issue */
}

.Home-blue-color {
  color: #3A70A6;
  text-align: end !important;
}

.Home-genie {
  animation: Home-genie-bounce-up 3s ease-in-out infinite;
  width: 100%;
}

.Home-badge {
  background: #ff9f40;
  box-shadow: 0px 6px 20px 0px #bbbbbb;
  color: #2C2C2C;
  font-size: 16px;
  font-weight: 400;
  margin-right: 16px;
  padding: 6px 16px 8px 16px;
}

.Home-projects img {
  transition: transform 0.2s;
}

.Home-projects img:hover {
  transform: scale(1.1);
}

.Home-projects video {
  transition: transform 0.2s;
}

.Home-projects video:hover {
  transform: scale(1.1);
}

.Home-projects h4 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
}

.Home-projects p {
  font-size: 24px;
  margin-top: 30px;
}

.Home-projects-more {
  color: #ff9f40;
  font-size: 24px;
  padding-left: 0;
  padding-right: 0;
}

.Home-projects-more:hover {
  color: #ff9f40;
  text-decoration: underline;
}

.Home-about {
  margin-bottom: 150px;
}

.Home-about p {
  font-size: 24px;
  max-width: 920px;
  text-align: center;
}

@keyframes Home-genie-bounce-up {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(32px);
  }
  100% {
    transform: translateY(0);
  }
}
