.IncidentTrackingApp p {
  font-size: 1.5em;
}

.IncidentTrackingApp ul {
  font-size: 1.5em;
  margin-bottom: 40px;
}

.IncidentTrackingApp h1 {
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 16px;
}

.IncidentTrackingApp h4 {
  color: #ff9f40;
  font-weight: 700;
  margin-bottom: 16px;
}

.IncidentTrackingApp-cover {
  background: #f2f2f2;
  margin-bottom: -2px; /* hack to solve a ghost line issue */
}

.IncidentTrackingApp-flowchart {
  background: #f2f2f2;
  margin-top: 100px;
  margin-bottom: -2px; /* hack to solve a ghost line issue */
}

.IncidentTrackingApp-badge {
  background: #3a70a6;
  box-shadow: 0px 6px 20px 0px #bbbbbb;
  font-size: 16px;
  font-weight: 400;
  margin-right: 16px;
  padding: 6px 16px 8px 16px;
}

.IncidentTrackingApp-hifi-row {
  margin-bottom: 100px;
}

.IncidentTrackingApp-hifi-row img {
  filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.08));
  width: 100%;
}
